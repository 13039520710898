<template>
  <div class="contents">
    <div class="container-fluid">
      <div class="prointerest-content mb-50">
        <div class="row">
          <div class="col-lg-12 mb-2 sticky">
            <div
              class="breadcrumb-main p-3"
              style="background-color: #f4f5f7 !important"
            >
              <h4 class="text-capitalize breadcrumb-title">
                Interest Approval
                <ul class="atbd-breadcrumb nav">
                  <li class="atbd-breadcrumb__item">
                    <router-link to="/">
                      <span class="la la-home"></span>
                    </router-link>
                    <span class="breadcrumb__seperator">
                      <span class="la la-slash"></span>
                    </span>
                  </li>
                  <li class="atbd-breadcrumb__item">
                    <span class="bread-item" style="color: #9299b8"
                      >Approval</span
                    >
                    <span class="breadcrumb__seperator">
                      <span class="la la-slash"></span>
                    </span>
                  </li>
                  <li class="atbd-breadcrumb__item">
                    <span class="bread-item">Interest</span>
                  </li>
                </ul>
              </h4>
            </div>
          </div>
          <div class="container-fluid overlay-clipped">
            <LoaderOverlay
              :active="true"
              v-if="!asyncLoader && loadingInterest"
              :zIndex="99"
              position="absolute"
              style="height: 100%"
            />
            <no-content v-if="noData" />
            <div class="row" v-if="interests.length">
              <div class="col-12">
                <div
                  class="
                    userDatatable
                    orderDatatable
                    sellerDatatable
                    global-shadow
                    border
                    mb-30
                    py-30
                    px-sm-30 px-20
                    bg-white
                    radius-xl
                    w-100
                  "
                >
                  <div class="table-responsive">
                    <table class="table mb-0 table-borderless border-0">
                      <thead>
                        <tr class="userDatatable-header">
                          <th scope="col">
                            <span class="userDatatable-title">No</span>
                          </th>
                          <th scope="col">
                            <span class="userDatatable-title">Name</span>
                          </th>
                          <th scope="col">
                            <span class="userDatatable-title">Username</span>
                          </th>
                          <th scope="col">
                            <span class="userDatatable-title">Subject</span>
                          </th>
                          <th scope="col">
                            <span class="userDatatable-title">Status</span>
                          </th>
                          <th scope="col" class="text-right">
                            <span class="userDatatable-title">Action</span>
                          </th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr v-for="(interest, k) in interests" :key="k">
                          <td>
                            <div class="d-flex align-items-center">
                              <div class="orderDatatable-title">
                                <p class="d-block mb-0">
                                  {{ paginationData.offset + k + 1 }}
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex align-items-center">
                              <div class="orderDatatable-title">
                                <p class="">
                                  <router-link
                                    :to="{
                                      name: 'officers-profile',
                                      params: {
                                        officer: { ulid: interest.ulid },
                                      },
                                    }"
                                  >
                                    {{ users[interest.ulid].name }}
                                  </router-link>
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div class="d-flex align-items-center">
                              <div class="orderDatatable-title">
                                <p class="">
                                  <router-link
                                    :to="{
                                      name: 'officers-profile',
                                      params: {
                                        officer: { ulid: interest.ulid },
                                      },
                                    }"
                                  >
                                    @{{ users[interest.ulid].username }}
                                  </router-link>
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <p>
                              {{ interest.name }}
                            </p>
                          </td>
                          <td>
                            <div
                              v-if="interest.status == 1"
                              class="orderDatatable-title"
                            >
                              <span
                                class="atbd-tag tag-success tag-transparented"
                                >Approved</span
                              >
                            </div>
                            <div
                              v-if="interest.status == 0"
                              class="orderDatatable-title"
                            >
                              <span
                                class="atbd-tag tag-warning tag-transparented"
                                >Pending</span
                              >
                            </div>
                            <div
                              v-if="interest.status == -1"
                              class="orderDatatable-title"
                            >
                              <span
                                class="atbd-tag tag-danger tag-transparented"
                                >Declined</span
                              >
                            </div>
                          </td>
                          <td class="text-right">
                            <div
                              class="slider-controller float-right w-30"
                              :class="{
                                'el-fade disabled': interest.status == 1,
                              }"
                              :data-toggle="interest.status == 1 ? '' : 'modal'"
                              data-target="#modal-approve-reject"
                              :id="`slider-${interest.inlid}`"
                            >
                              <div
                                class="
                                  custom-control custom-switch
                                  switch-primary switch-md
                                "
                              >
                                <input
                                  type="checkbox"
                                  class="custom-control-input"
                                  :id="interest.inlid"
                                  :checked="interest.status == 1"
                                />
                                <label
                                  class="custom-control-label"
                                  :for="interest.inlid"
                                  @click.prevent="selectInlid(interest, k)"
                                ></label>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div
                      class="modal-info-delete modal fade show"
                      id="modal-approve-reject"
                      tabindex="-1"
                      role="dialog"
                      aria-hidden="true"
                    >
                      <div
                        class="modal-dialog modal-sm modal-info"
                        role="document"
                      >
                        <div class="modal-content">
                          <div class="modal-body">
                            <div class="modal-info-body d-flex">
                              <div class="modal-info-icon warning">
                                <span data-feather="info"></span>
                              </div>
                              <div class="modal-info-text">
                                <h6>Are you sure you want to approve</h6>
                                <!-- <p>Some contents...</p> -->
                              </div>
                            </div>
                          </div>
                          <div class="modal-footer">
                            <button
                              type="button"
                              class="btn btn-danger btn-outlined btn-sm"
                              data-dismiss="modal"
                              @click="approveInterest(-1)"
                            >
                              Reject
                            </button>
                            <button
                              type="button"
                              class="btn btn-success btn-outlined btn-sm"
                              data-dismiss="modal"
                              @click="approveInterest(1)"
                            >
                              Approve
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- ends: col-12 -->
            </div>
          </div>
          <Pagination
            :url="interestUrl"
            :size="size"
            @pageChange="pageChange"
            @loading="loading"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { authComputed } from "@/vuex/helpers.js";
import Pagination from "@/components/Pagination.vue";
import { apiClient } from "@/plugins/restclient";
import NoContent from "@/components/NoContent.vue";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import { mapState } from "vuex";
export default {
  components: {
    Pagination,
    LoaderOverlay,
    NoContent,
  },
  data() {
    return {
      totalPage: 0,
      size: 10,
      interests: [],
      users: {},
      selectedInterest: "",
      loadingInterest: false,
      noData: false,
      interestUrl: "/v1/office/adjudicate/account/interest",
      paginationData: "",
    };
  },
  metaInfo: {
    title: "Greater Favour",
    titleTemplate: "%s | Interest Verification",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  computed: {
    ...mapState(["asyncLoader"]),
    ...authComputed,
  },
  beforeMount() {
    if (this.loggedIn == false) {
      this.$router.push({ path: "/login" });
    }
  },
  methods: {
    pageChange(dataSet, paginationData, response) {
      this.interests = dataSet;
      this.paginationData = paginationData;
      this.users = !response.error && response.info.users;
      if (this.interests.length == 0) {
        this.noData = true;
      } else {
        this.noData = false;
      }
    },
    loading(status) {
      this.loadingInterest = status;
    },
    selectInlid(interest, k) {
      this.selectedInterest = interest;
      this.selectedInterest.index = k;
    },
    approveInterest(status) {
      this.loadingInterest = true;
      apiClient
        .patch("/v1/engine/settings/interest/approve", {
          inlid: this.selectedInterest.inlid,
          status,
        })
        .then(() => {
          this.loadingInterest = false;
          if (status == 1) {
            document.getElementById(
              `${this.selectedInterest.inlid}`
            ).checked = true;
            document
              .getElementById(`slider-${this.selectedInterest.inlid}`)
              .classList.add("el-fade");
            document
              .getElementById(`slider-${this.selectedInterest.inlid}`)
              .classList.add("disabled");
          }

          this.selectedInterest.status = status;
          this.$set(
            this.interests,
            this.selectedInterest.index,
            this.selectedInterest
          );
        })
        .catch((err) => {
          this.loadingInterest = false;
          this.$store.commit("ctoast", {
            type: "danger",
            message: err.response.data.message,
            timeout: 7000,
          });
        });
    },
  },
};
</script>
